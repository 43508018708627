import * as _preact2 from "preact";

var _preact = "default" in _preact2 ? _preact2.default : _preact2;

var exports = {};
var _require = _preact,
    h = _require.h;

exports = function (props) {
  return h("div", {
    className: "uppy-Webcam-permissons"
  }, h("div", {
    className: "uppy-Webcam-permissonsIcon"
  }, props.icon()), h("h1", {
    className: "uppy-Webcam-title"
  }, props.hasCamera ? props.i18n("allowAccessTitle") : props.i18n("noCameraTitle")), h("p", null, props.hasCamera ? props.i18n("allowAccessDescription") : props.i18n("noCameraDescription")));
};

export default exports;