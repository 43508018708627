import * as _preact2 from "preact";

var _preact = "default" in _preact2 ? _preact2.default : _preact2;

import _formatSeconds from "./formatSeconds";
var exports = {};
var _require = _preact,
    h = _require.h;
var formatSeconds = _formatSeconds;

exports = function RecordingLength(_ref) {
  var recordingLengthSeconds = _ref.recordingLengthSeconds,
      i18n = _ref.i18n;
  var formattedRecordingLengthSeconds = formatSeconds(recordingLengthSeconds);
  return h("span", {
    "aria-label": i18n("recordingLength", {
      recording_length: formattedRecordingLengthSeconds
    })
  }, formattedRecordingLengthSeconds);
};

export default exports;