import * as _preact2 from "preact";

var _preact = "default" in _preact2 ? _preact2.default : _preact2;

import _CameraIcon from "./CameraIcon";
var exports = {};
var _require = _preact,
    h = _require.h;
var CameraIcon = _CameraIcon;

exports = function (_ref) {
  var onSnapshot = _ref.onSnapshot,
      i18n = _ref.i18n;
  return h("button", {
    className: "uppy-u-reset uppy-c-btn uppy-Webcam-button uppy-Webcam-button--picture",
    type: "button",
    title: i18n("takePicture"),
    "aria-label": i18n("takePicture"),
    onClick: onSnapshot,
    "data-uppy-super-focusable": true
  }, CameraIcon());
};

export default exports;